<template>
    <div class="cs_content">
        <div id="tab3" class="on">
            <div class="policy_title">
                <h2 style="margin-bottom:0px;">마케팅 정보 수신</h2>
                <div class="select-box-area" style="width:20%">
                    <div class="select-box" @mouseover.once="clickSelectBox()">약관버전정보</div>
                    <div class="select-box-dropDown">
                        <ul>
                            <li><p>약관버전정보</p></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="cs_list_box2">
                <h3>제 1장 총칙</h3>
                <div class="small_dan">
                    <p class="small_h" style="margin-bottom:0px;">제 1조 목적</p>
                    <p style="margin-bottom:0px;">
                        약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.
                    </p>
                </div>
                <div class="small_dan">
                    <p class="small_h" style="margin-bottom:0px;">제 2조 목적</p>
                    <p style="margin-bottom:0px;">
                        약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.약관 내용이
                        기입됩니다.약관 내용이 기입됩니다.약관 내용이 기입됩니다.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import $ from "jquery";

export default defineComponent({
  data () {
    return {
      selectBoxCheck: false
    };
  },
  methods: {
    clickSelectBox () {
      /* select_box_custom */
      $(document).off("click").on("click", ".select-box", (e) => {
        var select = $(e.currentTarget);
        if (this.selectBoxCheck) {
          $(document).mouseup((e) => {
            var seting = $(".select-box-dropDown");
            if (seting.has(e.currentTarget).length === 0) {
              seting.slideUp(300);
            }
            seting.css("box-shadow", "none");
            select.css("box-shadow", "none");
            select.removeClass("active");
          });
          this.selectBoxCheck = false;
        } else {
          // 드롭다운 열기
          select.next(".select-box-dropDown").slideDown(300);
          select
            .next(".select-box-dropDown")
            .css("box-shadow", "0 0 0 1px #FF2D55");
          select.css("box-shadow", "0 0 0 1px #FF2D55");
          select.addClass("active");
          this.selectBoxCheck = true;
        }
        // 다른영역 클릭 시 닫기
        $(document).mouseup((e) => {
          var seting = $(".select-box-dropDown");
          if (seting.has(e.currentTarget).length === 0) {
            seting.slideUp(300);
          }
          seting.css("box-shadow", "none");
          select.css("box-shadow", "none");
          select.removeClass("active");
        });
        select
          .next(".select-box-dropDown")
          .find("li")
          .click((e) => {
            var option = $(e.currentTarget).text();
            // 클릭 시 드롭다운 닫기
            $(".select-box-dropDown").slideUp(300);
            select.css("box-shadow", "none");
            select.removeClass("active");
            // select에 text 넣기
            select.text(option);
            if (option === '직접입력') {
              this.email2 = "";
              document.getElementById("email2").focus();
            } else {
              this.email2 = option;
            }
          });
      });
    }
  }
});
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
